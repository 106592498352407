export default class {
    constructor() {
        this.area = '#js-hover-area';
        this.group = '.js-hover-group';
        this.trigger = '.js-hover-group__trigger';
        this.contents = '.js-hover-group__contents';
        this.activeClass = 'is-active';
        this.init();
    }
    init() {
        const area = document.querySelector(this.area);
        const group = document.querySelectorAll(this.group);
        if(area) {
            area.addEventListener('mouseover', (e) => {
                if(group) {
                    const triggers = document.querySelectorAll(this.trigger);
                    const contents = document.querySelectorAll(this.contents);
                    triggers.forEach( (trigger) => {
                        trigger.addEventListener('mouseover',(e) => {
                            triggers.forEach((trg) => {
                                trg.classList.remove(this.activeClass);
                            })
                            contents.forEach((con) => {
                                con.classList.remove(this.activeClass);
                            })
                            // trigger.classList.remove(this.activeClass);
                            // trigger.nextElementSibling.classList.remove(this.activeClass);
                            trigger.classList.add(this.activeClass);
                            trigger.nextElementSibling.classList.add(this.activeClass);
                        })
                        // trg.addEventListener('mouseleave',(e) => {
                        //     trg.classList.remove(this.activeClass);
                        //     trg.nextElementSibling.classList.remove(this.activeClass);
                        // })
                    })
                }
            })
        }
    }
}