// import ScrollHint from 'scroll-hint';

export default class {
    constructor() {
        this.table = '#js-entry table';
        this.tableWrap();

        this.setIndex();
        this.initIndex();
    }
    tableWrap() {
        const tables = document.querySelectorAll(this.table);
        if(tables.length > 0) {
            tables.forEach( (table, i) => {
                // const elem = document.querySelector(table);
                table.outerHTML = "<div class='-table js-scrollable'>" + table.outerHTML + "</div>";
            })
            // new ScrollHint('.js-scrollable', {
            //     i18n: {
            //         scrollable: 'スクロールできます',
            //         suggestiveShadow: true
            //     }
            // });
        }
    }

    setIndex() {
        let indexWrap = document.createElement("div");
        let postContent = document.querySelector('#js-entry');
        let firstH2 = "";
        if(postContent) {
            firstH2 = postContent.querySelector('h2');
        }
        if(firstH2) {
            indexWrap.setAttribute('id', 'js-postIndex');
            indexWrap.classList.add('p-single-toc');
            indexWrap.innerHTML = '<div class="p-single-toc__header">目次</div>';
            firstH2.parentNode.insertBefore(indexWrap, firstH2);
        }
    }
    initIndex() {
        let indexWrap = document.querySelector('#js-postIndex');
        if(indexWrap) {
            let postContent = document.querySelector('#js-entry');
            let hTags = postContent.querySelectorAll('#js-entry>h2, #js-entry>h3');

            if (hTags.length > 0) {
    
                let indexList = document.createElement("ol"); 
                let listSrc = "";
                let h3List = "";    //h3タグを取得しておくための変数
        
                for (let i = 0; i < hTags.length; i++) {
        
                    let theHeading = hTags[i];
                    theHeading.setAttribute('id', "index_id" + i);  //リンクで飛べるようにIDをつける
        
                    if (theHeading.tagName === 'H2') {
        
                        if (h3List !== "") {
                            //h3リストが生成されていれば
                            listSrc += '<ul>' + h3List + '</ul>';
                            h3List = "";
                        } 
        
                        listSrc += '</li><li><a href="#index_id' + i + '">' + theHeading.textContent + '</a>';
        
                    } else if (theHeading.tagName === 'H3') {
        
                        h3List += '<li><a href="#index_id' + i + '">' + theHeading.textContent + '</a></li>';
        
                    }
        
                }
        
                if (h3List !== "") {
                    //最後のリストがh3だった場合
                    listSrc += '<ul>' + h3List + '</ul></li>';
                } else {
                    listSrc += '</li>';
                }
        
                indexList.classList.add('p-single-toc__list')
                indexList.innerHTML = listSrc;
                indexWrap.appendChild(indexList);
        
            }
        }
    }
}