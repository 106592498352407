import Swiper from'swiper/bundle';
import 'swiper/css/bundle';

export default class {
    constructor() {
        this.recommendSlider();
    }
    recommendSlider(){
        const recommendSlider = '#js-slider__recommend';
        new Swiper (recommendSlider,{
            effect:'slide',
            speed:1000,
            easing: "easeOutExpo",
            // autoplay: {
            //     delay: 4000
            // },
            slidesPerView: 1.85,
            spaceBetween: 60,
            pagination: {
                el: "#js-slider__recommend--pagination",
                clickable: true,
            },
            navigation: {
                nextEl: "#js-slider__recommend--next",
                prevEl: "#js-slider__recommend--prev"
            },
        })
    }
}