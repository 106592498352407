import Headroom from "headroom.js";

export default class {
    constructor() {
        this.init();
    }
    init() {
        const header = document.getElementById('js-header');
        const hamburger = document.getElementById('js-drawer__hamburger');

        if(header) {
            const headroomHeader = new Headroom(header);
            headroomHeader.init();
        }
        if(hamburger) {
            const headroomHamburger = new Headroom(hamburger);
            headroomHamburger.init();
        }
    }
}