import ScrollTrigger from '@terwanerik/scrolltrigger';
import {gsap} from "gsap";

export default class {
    constructor() {
        this.trigger = new ScrollTrigger();
        this.initialTrigger();
    }

    initialTrigger() {
        this.trigger.add( '[data-trigger]', {
            once: true,
            offset: {
                viewport: {
                    x: 0,
                    y: (trigger, frame, direction) => {
                        return trigger.visible ? 0 : 0.4
                    }
                }
            },
        })

        // text animation
        const animeElems = document.querySelectorAll('[data-trigger-animation]');
        if(animeElems.length > 0) {
            animeElems.forEach( (el) => {
                let _str = el.textContent.split("");
                let str = "";
                _str.map( s => {
                    str += "<span class='u-effect__text-animation--elem js-effect__text-animation'>" + s + "</span>";
                })
                el.innerHTML = str;
            })
            this.strs = document.querySelectorAll('.js-effect__text-animation');
            this.strs.forEach((str) => {
                gsap.set(str, {
                    // opacity: 0,
                    y: "100%"
                });
            })
        }

        // this.trigger.add('[data-trigger-animation]', {
        //     once: true,
        //     offset: {
        //         viewport: {
        //             y: (trigger, frame, direction) => {
        //                 return trigger.visible ? 0 : .3
        //             }
        //         }
        //     },
        //     toggle: {
        //         callback: {
        //             in: (trigger) => {
        //                 this.trigger_strs = trigger.element.querySelectorAll('.js-effect__text-animation');
        //                 this.trigger_strs.forEach( (str, i) => {
        //                     gsap.to(str, {
        //                         y: 0,
        //                         opacity: 1,
        //                         duration: .6,
        //                         ease: "expo.out",
        //                         delay: i * .075,
        //                     })
        //                 })
        
        //             }
        //         }
        //     }
        // })
    }

}