import ScrollHint from 'scroll-hint';

export default class {
    constructor() {
        this.elem = '.js-scrollable';
        this.init();
    }
    init() {
        const elem = document.querySelectorAll(this.elem);
        if(elem.length > 0) {
            new ScrollHint(this.elem, {
                i18n: {
                    scrollable: 'スクロールできます',
                    suggestiveShadow: true
                }
            })
        }
    }
}