export default class {
    constructor() {
        this.hero = '#js-hero';
        this.catch = '.js-hero-catch'
        this.activeClass = 'is-active';
        window.addEventListener('load', () => {
            setTimeout(() =>{
                this.heroInit();
            }, 200)
        })
    }
    heroInit() {
        const hero = document.querySelector(this.hero);
        if(hero) {
            const catches = document.querySelectorAll(this.catch);
            catches.forEach((c) => {
                c.classList.add(this.activeClass);
            })
        }
    }
}